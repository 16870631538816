import React , {useState,useEffect,createRef,useCallback,useContext,useRef} from "react"
import { useStaticQuery, graphql } from "gatsby"

//Gsap
import {CSSPlugin, Power4, Linear, gsap, mapRange } from 'gsap/all'
import { TimelineMax, TweenMax, TweenLite,TimelineLite} from "gsap";

import TransitionLink from 'gatsby-plugin-transition-link'

//context transition
import {TransitionContext} from '../context/transitionContext';



const Header = (props) => {

  const {animated, setanimated,finish,setfinish,to,setto} = useContext(TransitionContext)
  const {updateHeader,tos,actualLogo,setactualLogo} = props
  const data = useStaticQuery(graphql `
  query {
    logo: file(relativePath: { eq: "idea.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoBlanco: file(relativePath: { eq: "ideaBlanco.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)

  const [actualPosition,setactualPosition]= useState("")
  const [click,setclick]= useState(false)
  const preloaderRef = createRef<HTMLDivElement>()
  var ifHeader:Boolean;



  const changeLogoStyles = ()=>{
    if(!ifHeader){
      const vh = window.innerHeight;
      const logo = (document.querySelectorAll('#logo .logo')[0]);
      if(window.location.pathname === "/"){
        let positionTop = document.querySelector('body').getBoundingClientRect().top*-1
        if (positionTop > 0.9*vh) {
            logo.style.opacity = ("1")
        }else{
          logo.style.opacity = ("0")
        }
      
          if(positionTop > 1.9*vh && positionTop <5.65*vh){
            // setactualLogo("../ideaBlanco.png")
            logo.style.opacity = ("0")
          }else if(positionTop < 1.9*vh || positionTop >5.65*vh){
            logo.style.opacity = ("1")
            setactualLogo("../idea.png")
          }
        }
    }

  }
    useEffect(() => {
      window.addEventListener('popstate',  (event)=> {
        updateHeader();
      });
      if (window.matchMedia("(orientation: portrait)").matches) {
        console.log("portrait")
     }
     
     if (window.matchMedia("(orientation: landscape)").matches) {
        console.log("landScape")
     }
      setactualLogo("../idea.png")
      ifHeader = false;
      updateHeader();
      const vh = window.innerHeight;
      const logo = document.querySelectorAll('#logo .logo')[0];
      logo.style.opacity = ("1")
        window.addEventListener('scroll',function (e) {
          changeLogoStyles();
           
        });
      const menu = document.getElementById("menu-toggle")
      var logoVisible = true;
      menu.addEventListener("click",()=>{
        setclick(true)
        if(logoVisible){
          ifHeader=true
          setactualLogo("../ideaBlanco.png")
          logoVisible=false;
        }else{
          ifHeader=false
          setactualLogo("../idea.png")
          logoVisible=true;
        }
        changeHam()
      })
    }, []);
    useEffect(()=>{
      const text = document.getElementById("actualNav")
      const textAnim = new TimelineMax({ })
      textAnim.fromTo(text,1,{color:"black"},{color:"#0cb1f1"},'start').play()
    },[actualPosition])

    const changeHam =()=>{
      const menu = document.getElementById("menu-toggle")
      const menuSection = document.getElementsByClassName("menu-section")
      const nav = document.getElementsByTagName("nav")[0].getElementsByClassName("ul")
      const header = document.getElementsByTagName("header")
      header[0].classList.toggle("visible")
      menu.classList.toggle("on")
      for (let k = 0; k < menu.childNodes.length; k++) {
       menu.childNodes[k].classList.toggle("bgChange")
      }
      for (let k = 0; k < menuSection.length; k++) {
        menuSection[k].classList.toggle("on")
  
      }
      for (let k = 0; k < nav.length; k++) {
        nav[k].classList.toggle("hidden")
      }
    }
    const animationClose = useCallback(
      ()=>{
        const t2 = new TimelineMax({
          onComplete:()=>{
            setanimated(true)
          }
         })
        t2.to("#transicion",1,{left:"0",ease:Power4.easeInOut},"textIn")
        .to("#transicion h1",1,{left:"50%",ease:Power4.easeInOut},"textIn").play()
        ifHeader=false;
      },
      [],
    )

    const exitAnim=()=>{
      ifHeader=false;
      setfinish(true)
    }
    useEffect(()=>{
      if(animated && finish){
        ifHeader=false;
        if(click){
          changeHam()
          setclick(false)
        }
        const t2 = new TimelineMax({})
        t2.to("#transicion",1,{left:"-100%",ease:Power4.easeInOut},"textOut")
        .to("#transicion h1",1,{left:"150%",ease:Power4.easeInOut},"textOut").play()
        setanimated(false)
        setfinish(false)
        updateHeader();
      }
    },[animated,finish])
    const createLink = (name:string,border:boolean)=>{
      const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
      return(
        <li className={border?"bordes":null}>
        <TransitionLink id={`${name}Link`} to={`/${name==="inicio"?"":name}`} onClick={()=>{setto(nameCapitalized)}}
          exit={{trigger:()=>animationClose()}} entry={{trigger: () =>exitAnim() }}>
          <img src={`../header/${name}.svg`} className={`bg ${name}`} />
          <div className="black" />
          <h1>
            {nameCapitalized}
            <div className="subrayado" />
          </h1>
        </TransitionLink>
        </li>
      )

    }
  return (
    <>
      <div id="menu-toggle" className="menu-toggle">
        <div className="one"></div>
        <div className="two"></div>
        <div className="three"></div>
      </div>
      <header className="visible">
        <div className="menu-section">
          <nav>
            <div className="ul hidden">
                {tos.map((e,i)=>{
                  if (tos.length===4){
                    if(i===1 || i ===2){
                      return(
                        createLink(e,true)
                      )
                    }else{
                      return(
                        createLink(e,false)
                      )
                    }
                  }else{
                    if(i===1){
                      return(
                        createLink(e,true)
                      )
                    }else{
                      return(
                        createLink(e,false)
                      )
                    }
                  }
                })}
            </div>
          </nav>
        </div>
      </header>
      <TransitionLink id="logo" to={`/`} onClick={()=>{setto("Inicio")}}
          exit={{trigger:()=>animationClose()}} entry={{trigger: () =>exitAnim() }}>
        <img className="logo" src={actualLogo} />
      </TransitionLink>
      <div id="transicion">
        <h1>
          {to}
        </h1>
      </div>
    </>
  )

  }

  
export default Header
