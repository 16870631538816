import React , {useState} from "react"
import Header from "../components/header"

import "../styles/styles.scss"
// import  'animation.gsap'

//context transition
import {TransitionContext} from '../context/transitionContext';

export default  (props) => {
  const {children} = props
  const [animated, setanimated] = useState(false)
  const [finish,setfinish]  = useState(false)
  const [to,setto] = useState("test")
  const [tos,settos]= useState(["inicio","servicios","proyectos","contacto"])
  const[actualLogo,setactualLogo] = useState("../idea.png")
  const updateHeader =()=>{
    const logo = (document.querySelectorAll('#logo .logo')[0]);
    if(window.location.pathname === "/"){
      settos(["servicios","proyectos","contacto"])
    }else if((window.location.pathname).match(/servicios/g) !== null){
      logo.style.opacity = ("1")
      setactualLogo("../ideaBlanco.png")
      settos(["inicio","proyectos","contacto"])
    }else if((window.location.pathname).match(/proyectos/g) !== null){
      logo.style.opacity = ("1")
      if((window.location.pathname).match(/proyectos_/g) !== null){
        setactualLogo("../ideaBlanco.png")
      }else{
        setactualLogo("../idea.png")

      }
      settos(["inicio","servicios","contacto"])
    }else if((window.location.pathname).match(/contacto/g) !== null){
      logo.style.opacity = ("1")
      setactualLogo("../idea.png")
      settos(["inicio","proyectos","servicios"])
    }else{
      settos(["inicio","servicios","proyectos","contacto"])
    }
  }
  return (
    <>
      <div  className="layout-inner"> 
      <TransitionContext.Provider value={{animated,setanimated,finish,setfinish,to,setto,updateHeader}}>
        <Header updateHeader={updateHeader} tos={tos} actualLogo={actualLogo} setactualLogo={setactualLogo}/>
          <div >
            <main>{children}</main>
          </div>
      </TransitionContext.Provider>
      </div>

    </>
  )
}




