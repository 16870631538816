// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-tsx": () => import("./../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-proyectos-tsx": () => import("./../src/pages/proyectos.tsx" /* webpackChunkName: "component---src-pages-proyectos-tsx" */),
  "component---src-pages-servicios-automatizacion-tsx": () => import("./../src/pages/servicios/automatizacion.tsx" /* webpackChunkName: "component---src-pages-servicios-automatizacion-tsx" */),
  "component---src-pages-servicios-basculas-tsx": () => import("./../src/pages/servicios/basculas.tsx" /* webpackChunkName: "component---src-pages-servicios-basculas-tsx" */),
  "component---src-pages-servicios-energias-tsx": () => import("./../src/pages/servicios/energias.tsx" /* webpackChunkName: "component---src-pages-servicios-energias-tsx" */),
  "component---src-pages-servicios-ergonomia-tsx": () => import("./../src/pages/servicios/ergonomia.tsx" /* webpackChunkName: "component---src-pages-servicios-ergonomia-tsx" */),
  "component---src-pages-servicios-industrial-tsx": () => import("./../src/pages/servicios/industrial.tsx" /* webpackChunkName: "component---src-pages-servicios-industrial-tsx" */),
  "component---src-pages-servicios-software-tsx": () => import("./../src/pages/servicios/software.tsx" /* webpackChunkName: "component---src-pages-servicios-software-tsx" */),
  "component---src-pages-servicios-soluciones-tsx": () => import("./../src/pages/servicios/soluciones.tsx" /* webpackChunkName: "component---src-pages-servicios-soluciones-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */),
  "component---src-templates-proyectos-post-tsx": () => import("./../src/templates/proyectosPost.tsx" /* webpackChunkName: "component---src-templates-proyectos-post-tsx" */)
}

